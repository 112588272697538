/* eslint-disable no-undef */
import { io } from "socket.io-client";
import Auth from "../fc/Auth/Auth";
import { clearToast, showGooglePermissionError } from "../fc/helpers";
import ToastManager from "~/fc/ToastManager";
import {
  OUT_OF_CONVERSION_MINUTES,
  OUT_OF_CONVERSION_MINUTES_PER_TASK,
  INSUFFICIENT_PERMISSION,
  INVALID_CREDENTIALS,
} from "~/fc/Constants/task-error-code";
import {
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_EXPIRED,
  STATUS_TASK_DELETED,
} from "~/fc/Constants/job-status";
import { processInterruptedJobs } from "~/fc/Converter/ChunkUploadHandler";
import { EVENT_BUS } from "~/fc/constants";
import  { handleUserSubscriptionUpdate } from "~/fc/Auth/UserSubscriptionUpdate"

const skipJobCheck = [
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_EXPIRED,
  STATUS_TASK_DELETED,
];
const TASK_PROCESSING = "processing";
const TASK_COMPLETED = "completed";
const TASK_FAILED = "failed";
const BATCH_SIZE = 10;

export default async function ({ $auth, $axios, store }, inject) {
  const context = { $store: store, $auth: Auth };
  const toastManager = new ToastManager(context);

  let isFirstTime = true;
  let token;

  if (Auth.Token) {
    token = "Bearer " + Auth.Token;
  } else {
    try {
      const { data } = await $axios.get(process.env.ACCOUNT_SERVICE + "/guest");
      token = "Bearer " + data;
    } catch (error) {}
  }

  const socket = io(process.env.NOTIFICATION_SERVICE, {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 100,
    reconnectionDelayMax: 1000,
    transports: ["websocket"],
    path: process.env.SOCKET_PATH, // Need to add logic for staging/live
    auth: { token },
  });
  let showingToast = false;
  socket.on("disconnect", () => {
    $nuxt.$store.commit("setInternetAvailability", false);
  });
  socket.on("reconnect", () => {});
  let connectMsgTimer;
  socket.on("connect_error", () => {
    if (!$nuxt.$store.state.documentMounted) return;
    $nuxt.$store.commit("setInternetAvailability", false);
    connectMsgTimer = setTimeout(function () {
      if (showingToast === false) {
        showingToast = true;
        toastManager.add("warning", "", $nuxt.$t("socket_offline"), {
          offLine: true,
        });
      }
    }, 3000);
  });
  socket.on("reconnect_attempt", () => {});
  socket.on("reconnecting", () => {});
  socket.on("reconnect_error", () => {});
  socket.on("reconnect_failed", () => {});
  socket.on("subscription_updated", () => { handleUserSubscriptionUpdate() });
  socket.on("connect", (e) => {
    $nuxt.$store.commit("setInternetAvailability", true);
    if (connectMsgTimer) {
      clearTimeout(connectMsgTimer);
      connectMsgTimer = false;
    }
    if (showingToast) {
      clearToast();
      showingToast = false;
      toastManager.add("success", "", $nuxt.$t("socket_online"), {
        onLine: true,
      });
    }

    const jobs = $nuxt.$store.getters["items/getServerCreatedJobs"];

    if (isFirstTime) {
      for (const job in jobs) {
        jobs[job].tasks.forEach((value) => {
          reSubscribeToTask(value);
        });
      }
      isFirstTime = false;
    } else {
      socketReConnection(jobs);
    }

    // process interrupted jobs
    setTimeout(() => {
      processInterruptedJobs();
      $nuxt.$bus.$emit(EVENT_BUS.RESTART_STUCK_JOB_WATCHER);
    }, 3000);
  });

  socket.on("general_com_channel", (data) => {});

  socket.on("task_started", (data) => {
    $nuxt.$store.commit("items/processEvent", {
      name: "items/startTask",
      data,
    });
  });
  socket.on("task_failed", (task) => {
    const errorCode = task.result?.errorCode;
    const errorMsg = task.result?.msg || "";

    if (
      task.operation === "export/google-drive" &&
      (errorCode === INSUFFICIENT_PERMISSION ||
        errorCode === INVALID_CREDENTIALS)
    ) {
      showGooglePermissionError();
      $nuxt.$store.commit("setGoogleTokens", {
        oauthToken: false,
        gtokens: false,
      });
      return;
    }

    $nuxt.$store.commit("items/processEvent", {
      name: "items/taskFailed",
      data: task,
    });

    if (errorCode === OUT_OF_CONVERSION_MINUTES_PER_TASK) {
      $nuxt.$store.commit("banner/showPerTaskLimitReachedError");
      if ($nuxt.$store.state.subscription.name === "Free") {
        $nuxt.$gtm.push({ event: "rejected_free_minutes_per_task" });
      } else {
        $nuxt.$gtm.push({ event: "rejected_pro_minutes_per_task" });
      }
    } else if (errorCode === OUT_OF_CONVERSION_MINUTES) {
      $nuxt.$store.commit("banner/showLimitReachedError");
      if ($nuxt.$store.state.subscription.name === "Free") {
        $nuxt.$gtm.push({ event: "rejected_out_of_mins_free" });
      } else {
        $nuxt.$gtm.push({ event: "rejected_out_of_mins_pro" });
      }
    } else {
      const { jobs, tasks } = $nuxt.$store.state.items;
      if (
        tasks[task.id] &&
        jobs[tasks[task.id].job].tasks &&
        jobs[tasks[task.id].job].tasks.length > 3
      ) {
        $nuxt.$gtm.push({ event: "bulk_error" });
      } else {
        if (
          !task.dependsOn ||
          (Array.isArray(task.dependsOn) &&
            task.dependsOn.every(
              (taskId) => tasks[taskId].status === "completed"
            ))
        ) {
          // remove file/folder path from the error message
          const filePathRegex =
            /\/[^/\s]+\.[^/\s]+|\/[^/\s/]+|\\[^\\/\s]+\.[^\\/\s]+|\\[^\\/\s]+/g;
          $nuxt.$gtm.push({
            event: "task_error",
            event_category: task.name === "import" ? "upload" : task.name,
            error_msg: errorMsg.replace(filePathRegex, "").substring(0, 99),
          });
        }
      }
    }
  });
  socket.on("task_completed", (data) => {
    $nuxt.$store.commit("items/processEvent", {
      name: "items/taskCompleted",
      data,
    });
  });
  socket.on("task_updated", (data) => {
    $nuxt.$store.commit("items/processEvent", {
      name: "items/taskUpdated",
      data,
    });
  });
  socket.on("job_completed", (data) => {
    $nuxt.$socket.emit("unsubscribe", `job.${data.id}`);
  });
  socket.on("job_failed", (data) => {
    $nuxt.$socket.emit("unsubscribe", `job.${data.id}`);
  });
  socket.on("job_deleted", (data) => {
    $nuxt.$store.commit("items/jobDeleted", data);
  });
  socket.on("task_deleted", (data) => {
    $nuxt.$store.commit("items/processEvent", {
      name: "items/taskDeleted",
      data,
    });
  });

  inject("socket", socket);
}

async function socketReConnection(jobs) {
  const incompleteJobs = Object.fromEntries(
    Object.entries(jobs).filter(
      ([_, job]) => !skipJobCheck.includes(job.status)
    )
  );

  const jobKeys = Object.keys(incompleteJobs);
  const totalBatches = Math.ceil(jobKeys.length / BATCH_SIZE);

  for (let i = 0; i < totalBatches; i++) {
    if (!$nuxt.$store.state.isInternetAvailable) {
      break;
    }

    const startIndex = i * BATCH_SIZE;
    const endIndex = Math.min(startIndex + BATCH_SIZE, jobKeys.length);
    const batch = jobKeys.slice(startIndex, endIndex);

    const batchResults = await Promise.all(
      batch.map((jobId) =>
        checkJobProgressFromAPI(jobId, incompleteJobs[jobId])
      )
    );

    batchResults.forEach((isCompleted, index) => {
      const jobId = batch[index];
      if (!isCompleted) {
        incompleteJobs[jobId].tasks.forEach((taskId) =>
          reSubscribeToTask(taskId)
        );
      }
    });
  }
}

function reSubscribeToTask(task) {
  $nuxt.$socket.emit("subscribe", `task.${task}`);
}

async function checkJobProgressFromAPI(jobId, job) {
  try {
    const { data } = await $nuxt.$axios.get(
      process.env.PROCESS_SERVICE + "/jobs/" + jobId + "?short=yes"
    );
    if (data.tasks && data.tasks.length) {
      for (const value of data.tasks) {
        getTaskDetailsFromAPI(value);
      }
      return (
        data.status === TASK_COMPLETED ||
        data.status === TASK_FAILED ||
        data.status === "deleted"
      );
    } else if (data.status === "deleted") {
      $nuxt.$store.commit("items/jobDeleted", data);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response) {
      // handle job not found/ server error here
      if (error.response.status === 404) {
        $nuxt.$store.commit("items/jobDeleted", job);
      }
    }
    return false;
  }
}

function getTaskDetailsFromAPI(data) {
  data.id = data._id;
  if (data.status === TASK_COMPLETED) {
    $nuxt.$store.commit("items/taskCompleted", data);
  } else if (data.status === TASK_FAILED) {
    $nuxt.$store.commit("items/taskFailed", data);
  } else if (data.status === TASK_PROCESSING) {
    $nuxt.$store.commit("items/startTask", data);
  }
}
